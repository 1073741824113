<template>
    <v-layout row wrap class="white mt-5 mb-5 pa-4 ml-0 mr-0">
        <v-flex xs8 class="">
            <span class="caption"><b>CLAPP</b></span>
            <span class="caption ml-3 mr-3"><b><v-icon class="indigo--text"> mdi-arrow-right </v-icon></b></span>
            <span class="caption all-caps"><b>{{menu}}</b></span>
            <span class="caption ml-3 mr-3"><b><v-icon class="indigo--text"> mdi-arrow-right </v-icon></b></span>
            <span class="caption all-caps"><b>{{page}}</b></span>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props : {
        menu : String,
        page : String
    }
}
</script>

<style scoped>
    .all-caps{
        text-transform: uppercase;
    }
</style>
<template>
    <div class="pt-10 pa-5">
      <center>
        <v-layout row wrap style="max-width:700px;text-align:left" class="white">
            <v-flex v-for="item in pages" :key="item._id" style="min-height:100px"  class="mt-2 xs12 white">
              <div v-html="item.content" class="pa-5" v-if="item.type == 'text'"></div>
              <div v-if="item.type == 'image'"><img :src="item.content" style="width:100%" /></div>
              <div v-if="item.type=='video'">
                <video width="100%" controls><source :src="item.content" type="video/mp4">Your browser does not support HTML video.</video>
              </div>
            </v-flex>
          </v-layout>
      </center>
    </div>
</template>

<script>
import Axios from 'axios'
import {BASE_URL} from '../config'

export default {
    data(){
        return{
            content : "",
            selectedColumn : "",
            selectedType : "",
            tab : "",
            dialog_text : false,
            dialog_image : false,
            dialog_video : false,
            media_uploaded : false,
            pages : [],
            selectedColumn : "",
        }
    },
   
    mounted(){
      this.getPages()
    },
    methods : {
       uploadSuccess(value) {
            this.media_uploaded = true
            this.content = value
        },
        async add(){
            let url = BASE_URL + "/pages/"+this.$route.params.id
            let payload = {}
            let {data} = await Axios.post(url,payload)
            this.getPages()
        },
        async getPages(){
          let url = BASE_URL + "/pages/"+this.$route.params.id
          let {data} = await Axios.get(url)
          this.pages = data
        },
        async openEditor(item,type){
          this.selectedType = item.type
          this.selectedColumn = item._id
          this.content = item.content
          if(type == "TEXT"){
            this.dialog_text = true
          }else if(type == "IMAGE"){
            this.dialog_image = true
          }else if(type == "VIDEO"){
            this.dialog_video = true
          }
          
        },
        async save(type){
          let url = BASE_URL + "/pages/"+this.selectedColumn
          let payload = {
            type : type,
            content : this.content
          }
          let result = await Axios.put(url,payload)
          this.content = ""
          this.media_uploaded = false
          this.dialog_video = false
          this.dialog_image = false
          this.dialog_text = false
          this.getPages()
        }
    }
}
</script>

<style>
    .indigo-border{
        border: 1px solid #3f51b5;
    }
    .grey-border{
        border: 1px solid #b0b0b0;
    }
</style>
<template>
    <div class="pl-10 pr-10 mt-10">
        <v-layout row wrap>
            <v-flex xs12>
                <bread-crumbs :menu="'classes'" :page="'subjects'"></bread-crumbs>
            </v-flex>
           

            <v-flex xs12 class="mt-10">
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            #
                        </th>
                        <th class="text-left">
                            Title
                        </th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(item,index) in subjects"
                        :key="item.name"
                        @click="viewChapters(item._id)"
                        >
                        <td>{{index + 1}}</td>
                        <td><b>{{ item.title }}</b></td>
                       
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-flex>
        </v-layout>
        <v-dialog v-model="dialog" width="700">
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit</v-card-title>
          <v-card-text class="pt-5">
          <v-text-field v-model="subject" single-line outlined></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn text  @click="save('text')">SAVE</v-btn>
          </v-card-actions>
      </v-card>
     </v-dialog>
    </div>
    
</template>


<script>
import Axios from 'axios'
import { BASE_URL } from '../config'
import BreadCrumbs from '../components/BreadCrumbs.vue'
export default {
  components: { BreadCrumbs },
    data(){
        return {
            subjects : [],
            title : '',
            subject : '',
            dialog : false,
            subject_id : ''
        }
    },
    async mounted(){
        this.getSubjects()
    },
    methods : {
        async getSubjects(){
            this.$store.dispatch("SET_PROGRESS",true)
        let url = BASE_URL + "/subjects/"+this.$route.params.id
        let {data} = await Axios.get(url)
        this.subjects = data
        this.$store.dispatch("SET_PROGRESS",false)
        },
        edit(subject,id){
            this.subject = subject
            this.subject_id = id
            this.dialog = true
        },
        async save(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/subjects/"+this.subject_id
            let payload = {
                title : this.subject
            }
            let {data} = await Axios.put(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
            this.dialog = false
            this.getSubjects()
        },
        async addClass(){
            this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/subjects/"+this.$route.params.id
            let payload = {
                title : this.title 
            }
            let {data} = await Axios.post(url,payload)
            this.$store.dispatch("SET_PROGRESS",false)
        },
        viewChapters(id){
            this.$router.push({name : 'Chapters',params : {id : id}})
        },
        viewLessons(id){
            this.$router.push({name : 'Lessons',params : {course_id : id}})
        },
        editCourse(id){
            this.$router.push({name : 'EditClass',params : {id : id}})
        }
    }
}
</script>

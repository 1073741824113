<template>
    <v-container class="pt-10">
        <v-layout row wrap>
            <!-- <v-flex xs12 class="white pa-10">
                <v-btn outlined rounded @click="textBook" color="indigo" class="ml-2 mr-2">Text Book</v-btn>
                <v-btn outlined rounded @click="video" color="grey" class="ml-2 mr-2">Video</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Play Ground</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">FAQ</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Notes</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Online Exam</v-btn>
            </v-flex> -->

            <v-flex xs12 class="white pa-10">

                <div class="mt-5 mb-5">
                    <p class="caption pl-2"><b>LISTEN</b> </p>
                    <audio controls style="width:100%">
                    
                    <source src="../assets/audio/001.mp3" type="audio/mpeg">
                    Your browser does not support the audio element.
                    </audio>
                    <br><br>
                    <img src="https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba001.png" alt="" style="width:100%">
                    <video width="100%" controls>
                    <source src="../assets/videos/002.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                    <img src="https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba002.png" alt="" style="width:100%">
                    <img src="https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba003.png" alt="" style="width:100%">
                    <video width="100%" controls>
                    <source src="../assets/videos/003.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                    <img src="https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba004.png" alt="" style="width:100%">
                    <video width="100%" controls>
                    <source src="../assets/videos/004.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                    <img src="https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba005.png" alt="" style="width:100%">
                </div>
            </v-flex>
        </v-layout>
        
    </v-container>
    
</template>

<script>
export default {
    data(){
        return{
            textBook : [
                "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba001.png",
                "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba002.png",
                "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba003.png",
                "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba004.png",
                "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/amoeba/amoeba005.png",
            ]
        }
    },
    methods : {
        video() {
            this.$router.push('/video')
        }
    }
}
</script>
<template>
<v-container>
    <v-layout row wrap>
        <v-flex xs12>
            <p class="white pa-3 mt-10"><b>STD 10  <span class="ml-3 mr-3">/</span>  <span class="pink--text">BIOLOGY</span> <span class="ml-3 mr-3">/</span> CHAPTERS</b></p>
        </v-flex>
        <v-flex xs12>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left">
                       #
                    </th>
                    <th class="text-left">
                     CHAPTERS
                    </th>
                     <th class="text-left">
                        ACTION
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(item,index) in lessons"
                    :key="item"
                    >
                    <td >{{index+1}}</td>
                    <td style="text-transform:uppercase">{{ item }}</td>
                    <td class="green--text" @click="learn(item)"><b>LEARN</b></td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-flex>
    </v-layout>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            lessons : [
                "Life Processes",
                "Control and Coordination",
                "How Do Organisms Reproduce?",
                "Heredity and Evolution",
                "Environment",
                "Management of Natural Resources",
            ]
        }
    },
    methods : {
        learn(item){
           
                this.$router.push('/lesson')
            
        }
    }
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lesson from '../views/Lesson.vue'
import Lessons from '../views/Lessons.vue'
import Video from '../views/Video'
import Login from '../views/Login'
import Classes from '../views/Classes'
import Subjects from '../views/Subjects'
import Chapters from '../views/Chapters'
import ViewChapter from '../views/ViewChapter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path : '/lesson',
    name : 'Lesson',
    component : Lesson
  },
  {
    path : '/lessons',
    name : 'Lessons',
    component : Lessons
  },
  // {
  //   path : '/',
  //   name : 'Login',
  //   component : Login
  // },
  {
    path : '/video',
    name : 'Video',
    component : Video
  },
  {
    path : '/',
    name : 'Classes',
    component : Classes
  },
  {
    path: '/view-chapter/:id',
    name: 'ViewChapter',
    component: ViewChapter
  },
  {
    path : '/subjects/:id',
    name : 'Subjects',
    component : Subjects
  },
  {
    path : '/chapters/:id',
    name : 'Chapters',
    component : Chapters
  },
  {
    path: '/view-chapter/:id',
    name: 'ViewChapter',
    component: ViewChapter
  },
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div class="home">
    <v-container class="pt-10">
      <Welcome/>
      <br><br>
      
      <Subjects/>
    </v-container>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Subjects from '@/components/Subjects.vue'
import Welcome from '@/components/Welcome.vue'

export default {
  name: 'Home',
  components: {
    Subjects,
    Welcome
  }
}
</script>

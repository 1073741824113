<template>
    <v-layout row wrap class="white" style="border-radius:15px">
        <v-flex xs12 md6 class="pa-10" >
            <h3 class="indigo--text text--darken-4">WELCOME BACK, DEMO USER!</h3>
            <p class="caption" style="text-transform:uppercase">You have spent <span class="pink--text">12 hours</span> on clapp this month.</p>
            
        </v-flex>
        <v-flex md2></v-flex>
        <v-flex xs12 md4>
            <chart></chart>
        </v-flex>
    </v-layout>
</template>


<script>
import Chart from './Chart.vue'
export default {
    components : {
        Chart
    }
}
</script>
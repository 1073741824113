<template>
    <v-layout row wrap class="white pa-10" style="border-radius:15px">
        <v-flex class="pa-2 mt-5" xs12>
            <p class="white"><b>YOUR SUBJECTS</b></p>
        </v-flex>
        
        <v-flex xs12 md3 class="pa-2" v-for="item in subjects" :key="item.subject">
            <v-card class="mx-auto">
                <v-img
                class="white--text align-end"
                height="200px"
                :src="item.thumbnail"
                ></v-img>
                <v-card-title>{{item.subject}}</v-card-title>
                <v-card-subtitle class="pb-0">
                {{item.subtitle}}
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn
                        color="indigo"
                        text
                        @click="start(item.subject)"
                    >
                        Start Learning
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>


<script>
export default {
    data(){
        return{
            subjects : [
                {
                    subject : "English",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/english.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "Physics",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/physics.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "Chemistry",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/chemistry.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "Biology",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/biology.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "Maths",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/maths.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "History",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/history.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                {
                    subject : "Geography",
                    thumbnail : "https://clp-demo-assets.s3.ap-south-1.amazonaws.com/geography.jpg",
                    subtitle : "5 Chapters, 12 Lessons"
                },
                
               

            ]
        }
    },
    methods : {
        start(subject){
            if(subject == 'Biology'){
                this.$router.push('/lessons')
            }
            
        }
    }
}
</script>
<template>
<v-container>
    <v-layout row wrap>
        <v-flex xs12 class="chite">
            <v-layout row wrap>
            <v-flex xs12 class="white pa-10">
                <v-btn outlined rounded @click="textBook" color="indigo" class="ml-2 mr-2">Text Book</v-btn>
                <v-btn outlined rounded @click="video" color="grey" class="ml-2 mr-2">Video</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Play Ground</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">FAQ</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Notes</v-btn>
                <v-btn outlined rounded @click="textBook" color="grey" class="ml-2 mr-2">Online Exam</v-btn>
            </v-flex>

            <v-flex xs12 class="white pa-10">
                <div class="mt-5 mb-5" v-for="item in textBook" :key="item">
                    <img :src="item" alt="" style="width:100%">
                </div>
            </v-flex>
        </v-layout>
        </v-flex>
        <v-flex xs12 class="text-center mt-10">
           
            <v-layout row wrap>
                <v-flex xs12 md4 class="pa-2">
                    <video width="100%" controls>
                    <source src="../assets/videos/002.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </v-flex>
                <v-flex xs12 md4 class="pa-2">
                    <video width="100%" controls>
                    <source src="../assets/videos/003.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </v-flex>
                <v-flex xs12 md4 class="pa-2">
                    <video width="100%" controls>
                    <source src="../assets/videos/004.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </v-flex>
            </v-layout>
            
            
        </v-flex>
    </v-layout>
</v-container>
    
</template>

<script>

export default {
    components : {
        
    },
    methods : {
        textBook(){
            this.$router.push('/lesson')
        }
    }
}
</script>